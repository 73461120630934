<script>
import ResourceFrame from "@/screens/resourcesIndex/components/ResourceFrame.vue";
import { SuawInputGroup, SuawHeading } from "@/components";
export default {
  name: "ListingYourInPersonEvent",
  components: { ResourceFrame, SuawInputGroup, SuawHeading }
};
</script>

<template>
  <ResourceFrame resource-side-nav-type="organizer">
    <SuawInputGroup direction="column" group-gap="quadruple" prevent-flex-grow>
      <h1 class="suaw-organizer-resource-heading">Listing Your <b>In-Person Event</b></h1>
      <SuawHeading
        class="suaw-organizer-resource-intro"
        level="3"
        content="To help our organizers reach as many writers as possible, we list all events on both Meetup.com and our own Shut Up & Write! website. As the organizer of an in-person event, you'll only need to worry about your Meetup.com listing for now, since our website will automatically list your Meetup event in our directory within 24 hours."
      />
      <h2 class="suaw-organizer-resource-subtitle">
        Meetup
      </h2>
      <p class="suaw-paragraph">
        Shut Up & Write! provides the resources for our network of organizers to use Meetup to list their events. That means the cost of your Meetup listing is on us! By listing events on the Meetup network, we help our organizers connect with writers all over the world.
      </p>
      <p class="suaw-paragraph">
        <b>Chapters:</b> Meetup organizes events into groups by location. At Shut Up & Write!, we call these 'chapters.' Our team will assign your event to a chapter. If there is no nearby local chapter, our team will work with you to set up a new chapter for your event.
      </p>
      <p class="suaw-paragraph">
        <b>To start a new chapter, we require two organizers at minimum,</b> so we ask all organizers who need a new chapter to find a co-host.
      </p>
      <p class="suaw-paragraph">
        If your event will be in an existing chapter, you may host on your own, but we still recommend finding a co-host to cover days when you might be away.
      </p>
      <h2 class="suaw-organizer-resource-subtitle">
        Setup
      </h2>
      <div class="suaw-organizer-resource-guide">
        <ol class="suaw-organizer-resource-guide__list">
          <li class="suaw-organizer-resource-guide__item">
            <p class="suaw-paragraph">Our Community Team will promote your Meetup account to the Event Organizer access level, so make sure you're logged into your organizer account on Meetup.com. If you haven't been promoted on Meetup, let us know.</p>
          </li>
          <li class="suaw-organizer-resource-guide__item">
            <p class="suaw-paragraph">Open your event template. A Community Team member will be sending the link to your event template via <a href='mailto:questions@shutupwrite.com'>questions@shutupwrite.com</a>.</p>
          </li>
          <li class="suaw-organizer-resource-guide__item">
            <p class="suaw-paragraph">Click Organizer tools > Edit</p>
          </li>
          <li class="suaw-organizer-resource-guide__item">
            <p class="suaw-paragraph">Click the Edit button at the top of your screen.</p>
          </li>
          <li class="suaw-organizer-resource-guide__item">
            <p class="suaw-paragraph">Change the fields below:</p>
            <ul>
              <li><p class="suaw-paragraph">Date</p></li>
              <li><p class="suaw-paragraph">Time</p></li>
              <li><p class="suaw-paragraph">Duration</p></li>
              <li><p class="suaw-paragraph">Location</p></li>
            </ul>
          </li>
          <li class="suaw-organizer-resource-guide__item">
            <p class="suaw-paragraph">Check that all of your event details are correct, and then click the red Publish button at the bottom of your screen.</p>
          </li>
        </ol>
      </div>
      <p class="suaw-paragraph">
        Congrats! Your writing event is now listed on Meetup.com.
      </p>
      <h2 class="suaw-organizer-resource-subtitle">
        Shut Up & Write! Website
      </h2>
      <p class="suaw-paragraph">
        Once you list your in-person event on Meetup, it will automatically appear in our directory within 24 hours on the <router-link :to="{ name: 'Home' }">shutupwrite.com</router-link> site. Find your listing on our <router-link :to="{ name: 'ChapterDiscovery' }">in-person events page</router-link>.
      </p>
      <p class="suaw-paragraph">
        Note: If your event doesn't show up on shutupwrite.com within 24 hours, please reach out to us at <a href='mailto:questions@shutupwrite.com'>questions@shutupwrite.com</a> so we can investigate.
      </p>
    </SuawInputGroup>
  </ResourceFrame>
</template>

<style lang="scss">
.suaw-paragraph {
  color: var(--sem-color-text-medium);
  line-height: var(--p-line-height);
  font-size: var(--p-font-size);
  font-weight: var(--p-font-weight);
}

.suaw-organizer-resource-intro {
  color: var(--sem-color-neutral-dark);
}
.suaw-organizer-resource-heading {
  margin: 0;
  padding: 0;
  color: #414142;
  font-family: Archer;
  font-size: 48px;
  font-style: normal;
  font-weight: 500;
  line-height: 125%;
}
.suaw-organizer-resource-subtitle {
  color: #414141;
  font-family: Archer;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 125%;
}
.suaw-organizer-resource-thumbnail {
  aspect-ratio: 2;
  @media (max-width: 650px) {
    aspect-ratio: 1.5;
  }
}
.suaw-organizer-resource-button {
  @media (max-width: 650px) {
    width: 100%;
  }
}
//list
.suaw-organizer-resource-guide {
  font-family: Roboto;
  color: var(--sem-color-text-medium);

  &__list {
    padding-left: 20px;
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  &__item::marker {
    font-size: 16px;
    font-weight: 700;
  }
}
</style>
